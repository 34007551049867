<template>
  <div class="about">
    <div class="navBar" @click="goback()">
      <div class="nb_img">
        <img class="img" src="../assets/img/return.svg" alt="" />
      </div>
      <div>充值记录</div>
    </div>
    <div class="content">
      <div class="c_items" v-for="(v, i) in data" :key="i">
        <div>会员续费</div>
        <div class="money" style="font-size: 1.6rem; font-weight: bold">-{{ v.money }}</div>
        <div>
          交易状态：
          <span v-if="v.status == 'true'">交易成功</span>
          <span v-if="v.status == 'false'" style="color: #fb4949">待生效</span>
        </div>
        <div>交易时间：{{ v.createTime }}</div>
        <div>交易单号：{{ v.id }}</div>
        <div>备注：{{ v.remarks }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRechargeInfo } from '@/api/member.js'
import session from '@/utils/session'
export default {
  data() {
    return {
      userInfo: null,
      data: []
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
    this.getInfo()
  },
  methods: {
    goback() {
      this.$router.push({ path: '/home' })
    },
    async getInfo() {
      const res = await getRechargeInfo(this.userInfo.id)
      if (res.success) {
        this.data = res.data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.navBar {
  padding: 1rem;
  margin-top: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  font-weight: bold;
  .nb_img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
  }
}
.content {
  padding: 0 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  .c_items {
    text-align: left;
    background: #f2f3f4;
    border-radius: 16px;
    padding: 1rem;
    margin-top: 1rem;
    div {
      padding: 0.4rem 0;
    }
    .money {
      font-weight: bold;
      color: #fb4949;
      margin-bottom: 1rem;
    }
  }
}
</style>