<template>
  <div class="home">
    <div class="card">
      <div class="card_inner">
        <div class="ci_topBox">
          <div class="cit_img">
            <img class="img" src="../assets/img/logo.png" alt="" />
          </div>
          <div class="cit_txt">
            <div class="citt_tit">早餐名称</div>
            <div class="citt_dec">这是一段描述</div>
          </div>
        </div>
        <div class="ci_bottom">ID:{{ userInfo.id }}</div>
      </div>
    </div>
    <div class="card_btn">
      <el-row :gutter="20">
        <el-col :span="12">
          <div
            class="grid-content bg-purple"
            @click="wxPay()"
            :style="{
              background: btnStatus == true ? 'rgba(251, 73, 73, 1)' : 'rgba(251, 73, 73, 0.1)',
              color: btnStatus == true ? '#fff' : 'rgba(251, 73, 73, 1)'
            }"
          >
            <div class="cb_left">微信充值</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="grid-content bg-purple cb_rightBox"
            @click="xjPay()"
            :style="{
              background: btnStatus == false ? 'rgba(251, 73, 73, 1)' : 'rgba(251, 73, 73, 0.1)',
              color: btnStatus == false ? '#fff' : 'rgba(251, 73, 73, 1)'
            }"
          >
            <div class="cb_right">现金充值</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content" v-if="tabStatus == true">
      <div class="c_items" v-for="(v, i) in data" :key="i">
        <div>会员续费</div>
        <div class="money">-{{ v.money }}</div>
        <div>
          交易状态：
          <span v-if="v.status == 'true'">交易成功</span>
          <span v-if="v.status == 'false'" style="color: #fb4949">待生效</span>
        </div>
        <div>会员手机：{{ v.mobile }}</div>
        <div>会员单号：{{ v.userId }}</div>
        <div>交易时间：{{ v.createTime }}</div>
        <div>交易单号：{{ v.id }}</div>
        <div>
          <el-button type="primary" @click="submitForm(v)">审核</el-button>
        </div>
      </div>
    </div>
    <div class="content" v-if="tabStatus == false">
      <div class="c_items">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item prop="mobile">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号（必填）"></el-input>
            <div class="codeDec" v-if="mobileRes == false">请输入正确手机号!</div>
          </el-form-item>
          <el-form-item prop="cardType">
            <el-select v-model="ruleForm.cardType" placeholder="请选择会员类型（必填）" @change="openSelect()">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="xjchecked">
            <el-select v-model="xjchecked" placeholder="是否会员升级（默认否）">
              <el-option v-for="item in xjcheckedJson" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="submitTo(ruleForm)">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 弹框 -->
    <div class="saveBox" v-if="saveShow == true">
      <div class="sb_content">
        <div class="sbc_tit">提示</div>
        <div class="sbc_dec">请核对会员信息，确认已经收款</div>
        <el-checkbox v-model="checked" @change="openCheckBox()">是否会员升级</el-checkbox>
        <div class="sbc_btn">
          <div class="sbct_item" @click="colse()">取消</div>
          <div class="sbct_item btn" @click="openSave()">通过</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRechargeInfo, getUserInfo, putRechargeInfo, upRechargeInfo, postxjPayInfo } from '@/api/member.js'
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  data() {
    return {
      userInfo: null,
      btnStatus: true,
      data: [],
      saveShow: false,
      newDate: null,
      tabStatus: true,
      checked: false,
      xjchecked: null,
      ruleForm: {
        mobile: null,
        userId: null,
        cardType: null,
        money: null,
        status: true,
        remarks: '如会员信息没有即时更新，请在首页右上角点击刷新一下'
      },
      options: [
        {
          value: 0,
          label: '无'
        },
        {
          value: 1,
          label: '基础会员'
        },
        {
          value: 2,
          label: '尊享会员'
        }
      ],
      xjcheckedJson: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ]
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
    this.ruleForm.userId = this.userInfo.id
    this.getInfo()
  },
  methods: {
    wxPay() {
      this.btnStatus = true
      this.tabStatus = true
    },
    xjPay() {
      this.btnStatus = false
      this.tabStatus = false
    },
    async getInfo() {
      const res = await getRechargeInfo(this.userInfo.id)
      if (res.success) {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].status == 'false') {
            const ress = await getUserInfo(res.data[i].userId)
            if (ress.success) {
              res.data[i].mobile = ress.data.mobile
              this.data.push(res.data[i])
            }
          }
        }
      }
    },
    colse() {
      this.saveShow = false
    },
    async submitForm(data) {
      this.saveShow = true
      this.newDate = data
    },
    async openSave() {
      this.newDate.status = true
      if (this.checked == true) {
        const res = await upRechargeInfo(this.newDate)
        if (res.success) {
          this.saveShow = false
          location.reload()
        }
      } else {
        const res = await putRechargeInfo(this.newDate)
        if (res.success) {
          this.saveShow = false
          location.reload()
        }
      }
    },
    openSelect() {
      if (this.ruleForm.cardType == 1) {
        this.ruleForm.money = 9.9
      } else if (this.ruleForm.cardType == 2) {
        this.ruleForm.money = 99
      }
    },
    async submitTo() {
      if (this.xjchecked == 1) {
        const res = await upRechargeInfo(this.ruleForm)
        if (res.success) {
          alert('提交成功')
          location.reload()
        }
      } else {
        const res = await postxjPayInfo(this.ruleForm)
        if (res.success) {
          alert('提交成功')
          location.reload()
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.card {
  margin: 0.6rem;
  .card_inner {
    background: #fb4949;
    border-radius: 16px;
    height: 10rem;
    padding: 1.2rem;
    .ci_topBox {
      display: flex;
      align-items: center;
      justify-content: start;
      .cit_img {
        width: 3rem;
        height: 3rem;
        background: #fff;
        border-radius: 5rem;
        margin-right: 1rem;
        overflow: hidden;
        border: 4px solid #fff;
      }
      .cit_txt {
        text-align: left;
        .citt_tit {
          font-size: 1.2rem;
          font-weight: bold;
          color: #fff;
        }
        .citt_dec {
          font-size: 0.8rem;
          color: #fff;
        }
      }
    }
    .ci_bottom {
      font-size: 1rem;
      color: #fff;
      text-align: left;
      margin-top: 5rem;
    }
  }
}

.card_btn {
  padding: 0 1rem;
  .grid-content {
    background: #fb4949;
    border-radius: 16px;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
  }
  .grid-content:active {
    opacity: 0.7;
  }
  .cb_rightBox {
    background: rgba(251, 73, 73, 0.1);
    color: #fb4949;
  }
}
.content {
  padding: 0 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  .c_items {
    text-align: left;
    background: #f2f3f4;
    border-radius: 16px;
    padding: 1rem;
    margin-top: 1rem;
    div {
      padding: 0.4rem 0;
    }
    .money {
      font-size: 1.8rem;
      font-weight: bold;
      color: #fb4949;
      margin-bottom: 1rem;
    }
    /deep/ .el-button {
      height: 3rem;
    }
    /deep/ .el-input__inner {
      background: #fff;
      font-size: 1rem;
    }
    /deep/ .el-select {
      width: 100%;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
}
.saveBox {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .sb_content {
    width: 20rem;
    background: #fff;
    border-radius: 16px;
    padding-top: 1rem;
    font-size: 1.2rem;
    .sbc_tit {
      font-weight: bold;
    }
    .sbc_dec {
      padding: 1rem 0;
    }
    .sbc_btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #f2f3f4;
      .sbct_item {
        width: 450px / 2;
        text-align: center;
        padding: 24px 0;
      }
      .btn {
        color: #fb4949;
        font-weight: bold;
      }
    }
  }
}
</style>
