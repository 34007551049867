<template>
  <div class="about">
    <div class="navBar" @click="goback()">
      <div class="nb_img">
        <img class="img" src="../assets/img/return.svg" alt="" />
      </div>
      <div>我的信息</div>
    </div>
    <div class="content">
      <div class="c_items">
        <div class="ci_tit">姓名</div>
        <div class="ci_input">
          <el-input v-model="userInfo.name" placeholder="未知"></el-input>
        </div>
      </div>
      <div class="c_items">
        <div class="ci_tit">手机</div>
        <div class="ci_input">
          <el-input v-model="userInfo.mobile" placeholder="未知"></el-input>
        </div>
      </div>
      <div class="c_items">
        <div class="ci_tit">性别</div>
        <div class="ci_input">
          <el-select v-model="userInfo.gender" placeholder="未知">
            <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="c_items">
        <div class="ci_tit">生日</div>
        <div class="ci_input">
          <el-date-picker v-model="userInfo.birthday" type="date" placeholder="未知"> </el-date-picker>
        </div>
      </div>
      <div class="btn">
        <el-button type="primary" @click="submitForm()">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { putUserInfo, getLogin, getProfile } from '@/api/member.js'
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  data() {
    return {
      userInfo: null,
      gender: [
        {
          value: 0,
          label: '未知'
        },
        {
          value: 1,
          label: '先生'
        },
        {
          value: 2,
          label: '女士'
        }
      ]
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
  },
  methods: {
    goback() {
      this.$router.push({ path: '/home' })
    },
    async submitForm() {
      const res = await putUserInfo(this.userInfo)
      if (res.success) {
        const token = await getLogin(this.userInfo)
        if (token.success) {
          session.setSession(token.data)
          this.parseToken()
        }
      }
    },
    //解析token
    async parseToken() {
      let result = await getProfile()
      session.setData('userInfo', result.data)
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.about {
  font-size: 1.2rem;
}
.navBar {
  padding: 1rem;
  margin-top: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: bold;
  .nb_img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.4rem;
  }
}
.content {
  padding: 1rem;
  .c_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    border-bottom: 1px solid #f2f3f4;
    padding: 0.4rem 0;
    .ci_tit {
      width: 10rem;
      text-align: left;
      font-size: 1.2rem;
    }
    .ci_input {
      width: 100%;
      .el-input /deep/ .el-input__inner {
        text-align: right;
        background: #fff;
        padding: 0;
      }
      /deep/ .el-select {
        width: 100%;
      }
      .el-select /deep/ .el-input__suffix {
        display: none;
      }
      .el-select /deep/ .el-input__inner {
        background: #fff;
        text-align: right;
      }
      /deep/ .el-input__prefix {
        display: none;
        text-align: right;
      }
      /deep/.el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }
      /deep/ .el-input--suffix .el-input__inner {
        padding-right: 0;
      }
    }
  }
  .btn {
    margin-top: 4rem;
  }
}
</style>