<template>
  <div class="about">
    <div class="navBar" @click="goback()">
      <div class="nb_img">
        <img class="img" src="../assets/img/return.svg" alt="" />
      </div>
      <div>会员详情</div>
    </div>
    <div class="content">
      <div class="c_items">
        <div>特权说明：本卡为本店早餐会员卡，可享受本店会员待遇。</div>
        <div>有效日期：月卡，具体截止时间以首页显示为准}</div>
        <div>电 话：137 1834 5029</div>
        <div style="font-weight: bold;margin-top:1rem">使用须知：</div>
        <div class="ci_dec">
          每人限领一张 </br>
          1、结账时请出示本会员卡；</br>
          2、凭此卡首页折扣信息可参与店内优惠活动；</br>
          3、会员凭此卡可以免费获取一份会员菜品；</br>
          4、会员可在首页投票选择下周会员免费商品，按照投票结果优选前四名，排名相同则随机选取；</br>
          5、会员卡开通后非店内不可控因素外，不支持退款
        </div>
      <div class="wxhBox">
        <div class="wxImg">
          <img class="img" src="../assets/img/wxh.svg" alt="" />
        </div>
        <div>
        <div>微信号</div>
        <div>13718345029</div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goback() {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.navBar {
  padding: 1rem;
  margin-top: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  font-weight: bold;
  .nb_img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
  }
}
.content {
  padding: 0 1rem;
  font-size: 1rem;
  .c_items {
    text-align: left;
    margin-top: 1rem;
    div {
      padding: 0.4rem 0;
    }
    .ci_dec {
      line-height: 2;
    }
    .wxhBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .wxImg {
        width: 8rem;
        height: 8rem;
        margin-right: 2rem;
      }
    }
  }
}
</style>