import Vue from 'vue'
import VueRouter from 'vue-router'
import LogonIndex from '../components/logonIndex.vue' // 登录
import LogonSys from '../components/logonSys.vue' // 系统登录
import SysHomeView from '../views/SysHomeView.vue' // 系统首页
import Home from '../views/HomeView.vue' // 首页
import My from '../views/My.vue' // 我的信息
import Recharge from '../views/Recharge.vue' // 充值记录
import MemberDetails from '../views/MemberDetails.vue' // 会员详情
import MemberDishes from '../views/MemberDishes.vue' // 会员菜品
import Pay from '../views/Pay.vue' // 支付66
import Pay2 from '../views/Pay2.vue' // 支付9.9
import MemberVote from '../views/MemberVote.vue' // 会员菜品投票

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'logonIndex',
    component: LogonIndex
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/my',
    component: My
  },
  {
    path: '/recharge',
    component: Recharge
  },
  {
    path: '/memberDetails',
    component: MemberDetails
  },
  {
    path: '/memberDishes',
    component: MemberDishes
  },
  {
    path: '/pay',
    component: Pay
  },
  {
    path: '/pay2',
    component: Pay2
  },
  {
    path: '/memberVote',
    component: MemberVote
  },
  {
    path: '/logonSys',
    component: LogonSys
  },
  {
    path: '/sysHomeView',
    component: SysHomeView
  },
]

const router = new VueRouter({
  routes
})

export default router
