import request from '@/utils/request'

// 获取菜品
export function getFoodsList() {
  return request({
    url: '/sys/foods/list',
    method: 'get'
  })
}
// 投票
export function putFoodsList(data, userId) {
  return request({
    url: '/sys/foods/put/' + userId,
    method: 'post',
    data: data
  })
}