<template>
  <div class="about">
    <div class="navBar" @click="goback()">
      <div class="nb_img">
        <img class="img" src="../assets/img/return.svg" alt="" />
      </div>
      <div>本周会员菜品</div>
    </div>
    <div class="content">
        <div style="font-weight: bold;margin-top:1rem；padding: 0.4rem 0;">使用须知：</div>
        <div class="ci_dec" style="padding: 0.4rem 0;">
            1、会员凭此卡可以免费获取一份会员菜品；</br>
            2、会员可在首页投票选择下周会员免费商品，按照投票结果优选前四名，排名相同则随机选取；
        </div>
      <el-row :gutter="20">
        <el-col :span="12" v-for="(v,i) in data" :key="i">
          <div class="grid-content bg-purple">
            <div class="bp_img">
              <img :src="v.img" alt="">
            </div>
            <div class="bp_tit">{{v.title}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getFoodsList } from '@/api/foods.js'
export default {
  data() {
    return {
      data: []
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    goback() {
      this.$router.push({ path: '/home' })
    },
    async getInfo() {
      const res = await getFoodsList()
      if (res.success) {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].memberDishes == 1) {
            this.data.push(res.data[i])
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.navBar {
  padding: 1rem;
  margin-top: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  font-weight: bold;
  .nb_img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
  }
}
.content {
  padding: 0 1rem;
  text-align: left;
  margin-top: 1rem;
  font-size: 1rem;
  .ci_dec {
    line-height: 2;
  }
  .grid-content {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 1rem;
    background: #f2f3f4;
  }
  .bp_img {
    overflow: hidden;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bp_tit {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.4rem 1rem;
  }
}
</style>