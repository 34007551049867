<template>
  <div class="about">
    <div class="navBar" @click="goback()">
      <div class="nb_img">
        <img class="img" src="../assets/img/return.svg" alt="" />
      </div>
      <div>会员菜品投票</div>
    </div>
    <div class="content">
      <div class="ci_dec">感谢您对我们的支持与信任！</div>
      <div class="ci_dec">
        为进一步满足全体会员的早餐需求，提高我们的服务质量，我们推出每周更新一次会员可享菜品，并面向全体会员参与菜品投票特权；
        每位会员每周仅限一次投票权利，一次可选择四种菜品作为下周会员菜品备选方案；
        投票通道每周日下午14:00截止，我们将根据投票结果选择前四名作为下周会员可享菜品。
      </div>
      <div class="ci_tit">1、请从一下菜品中选择您最喜欢的4种菜品</div>
      <el-row :gutter="20" class="dataList">
        <el-col :span="12" v-for="(v, i) in data" :key="i">
          <div class="grid-content bg-purple">
            <div class="bp_img">
              <img :src="v.img" alt="" />
            </div>
            <div class="bp_tit">{{ v.title }}</div>
            <div class="bp_dec">第{{ v.ranking }}名 | {{ v.votes }}票</div>
            <div class="bp_checkbox">
              <el-checkbox
                v-model="v.status"
                @change="openCheckBox(v)"
                v-if="v.status == true"
                label="已选"
                style="background: #fb4949"
                border
              ></el-checkbox>
              <el-checkbox
                @change="openCheckBox1(v)"
                v-model="v.status"
                v-if="v.status == false"
                label="可选"
                border
              ></el-checkbox>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="btnBox">
      <div class="bb_inner">
        <div class="bb_tit">已选择{{ this.newData.length }}种</div>
        <el-button class="bb_btn" type="primary" @click="submitForm()" v-if="userInfo.vote != '1'">投票</el-button>
        <el-button class="bb_btn" plain disabled v-if="userInfo.vote == '1'">已投票</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getFoodsList, putFoodsList } from '@/api/foods.js'
import { getLogin, getProfile } from '@/api/member.js'
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  data() {
    return {
      userInfo: null,
      data: [],
      newData: []
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const res = await getFoodsList()
      if (res.success) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].status = false
          this.data.push(res.data[i])
        }
      }
    },
    goback() {
      this.$router.push({ path: '/home' })
    },
    openCheckBox(v) {
      var newData = this.newData.filter(function (item) {
        return item.title != v.title
      })
      this.newData = newData
    },
    openCheckBox1(v) {
      if (this.newData.length < 4) {
        v.status = true
        this.newData.push(v)
      } else {
        var newData = this.data.filter(function (item) {
          if (item.title == v.title) {
            item.status = false
          }
          return item
        })
        this.data = newData
      }
    },
    async submitForm() {
      if (session.getUserInfo().vote != 1) {
        const res = await putFoodsList(this.newData, session.getUserInfo().id)
        if (res.success) {
          const token = await getLogin(this.userInfo)
          if (token.success) {
            session.setSession(token.data)
            this.parseToken()
          }
        }
      }
    },
    //解析token
    async parseToken() {
      let result = await getProfile()
      session.setData('userInfo', result.data)
      location.reload()
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.navBar {
  padding: 1rem;
  margin-top: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  font-weight: bold;
  .nb_img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
  }
}
.content {
  padding: 0 1rem;
  text-align: left;
  margin-top: 1rem;
  font-size: 1rem;
  .ci_dec {
    line-height: 2;
  }
  .ci_tit {
    font-weight: bold;
    margin-top: 1rem;
  }
  .grid-content {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 1rem;
    background: #f2f3f4;
  }
  .bp_img {
    overflow: hidden;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bp_tit {
    font-weight: bold;
    padding: 0.4rem 1rem;
  }
  .bp_dec {
    font-size: 0.8rem;
    padding: 0 1rem;
    opacity: 0.7;
  }
  .bp_checkbox {
    padding: 1rem;
  }
  .el-checkbox /deep/ .el-checkbox__label {
    font-size: 1rem;
  }
  /deep/ .el-checkbox.is-bordered {
    width: 100%;
    padding: 1.2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fb4949;
    color: #fb4949;
  }
  /deep/ .el-checkbox__inner {
    width: 1.2rem;
    height: 1.2rem;
  }
  /deep/ .el-checkbox__inner::after {
    width: 0.6rem;
    height: 1rem;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #fff;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    color: #fff;
    background: #fb4949;
    border-color: #fb4949;
  }
  .dataList {
    margin-bottom: 8rem;
  }
}
.btnBox {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  box-shadow: 0 -0.4rem 0.4rem rgba(0, 0, 0, 0.05);
  z-index: 9999;
  font-size: 1rem;
  .bb_inner {
    padding: 0.4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bb_tit {
      font-weight: bold;
    }
    .bb_btn {
      width: 10rem;
    }
  }
}
</style>