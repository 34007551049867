import request from '@/utils/request'

// 发送验证码
export function getSignValidateCode(mobile) {
    return request({
        url: '/sys/member/signValidateCode/' + mobile,
        method: 'get',
    })
}
// login登录
export function getLogin(data) {
    return request({
        url: '/sys/member/login',
        method: 'post',
        data: data
    })
}
// 解析token
export function getProfile() {
    return request({
        url: '/sys/member/profile',
        method: 'post'
    })
}
// 更新信息
export function putUserInfo(data) {
    return request({
        url: '/sys/member/' + data.id,
        method: 'put',
        data: data
    })
}
// 根据id查询
export function getUserInfo(id) {
    return request({
        url: '/sys/member/' + id,
        method: 'get'
    })
}
// 根据id查询充值记录
export function getRechargeInfo(userId) {
    return request({
        url: '/sys/recharge/findByUserIdList/' + userId,
        method: 'get'
    })
}
// 根据id查询保存充值记录
export function postRechargeInfo(data) {
    return request({
        url: '/sys/recharge',
        method: 'post',
        data: data
    })
}
// 根据id查询审核充值
export function putRechargeInfo(data) {
    return request({
        url: '/sys/recharge/' + data.id,
        method: 'put',
        data: data
    })
}
// 根据id查询审核升级充值
export function upRechargeInfo(data) {
    return request({
        url: '/sys/recharge/up/' + data.id,
        method: 'put',
        data: data
    })
}
// 现金充值提交
export function postxjPayInfo(data) {
    return request({
        url: '/sys/recharge/xjPay/' + data.mobile,
        method: 'post',
        data: data
    })
}
