// 文件 api/request.js
import axios from 'axios';
import store from '@/store'
import { Mseeage, Loading } from 'element-ui'
import session from './session'
let reqCount = 0
let tokenFail = null
let loading;

const service = axios.create({
  baseURL: process.env.VUE_API, // VUE_API 在.env.development等文件中配置的环境变量 不同环境下的请求地址
  // baseURL:"http://kwangda.com:8090", // VUE_API 在.env.development等文件中配置的环境变量 不同环境下的请求地址
  timeout: 60000, // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  requset => {
    reqCount++
    const token = session.getSession()
    loading = Loading.service({
      fullScreen: true, // 全屏loading
      background: 'transparent', // 背景色
    })
    if (token) {
      requset.headers.Authorization = `Bearer ${token}`
    }
    // if(store.user.token){ // 请求头添加 token与userName
    // 	request.headers.token = store.user.token
    // 	request.headers.userName = store.user.userName
    // }
    return requset
  },
  error => {
    loading.close()
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    reqCount--
    if (reqCount === 0) loading.close()
    if (response.data.respCode === 111) { // token失效处理
      if (!tokenFail) {
        tokenFail = setTimeOut(() => {
          store.dispatch('user/logout') // 退出登陆
          Message.error(response.data.message) // 提示语
          tokenFail = null
          return new Promise.reject(new Error('token已失效'))
        }, 200)
      }
    }
    return response.data
  },
  error => {
    loading.close()
    reqCount--
    return Promise.reject(error)
  }
)

export default service
