<template>
  <div class="about">
    <div class="navBar" @click="goback()">
      <div class="nb_img">
        <img class="img" src="../assets/img/return.svg" alt="" />
      </div>
      <div>支付</div>
    </div>
    <div class="content">
      <div style="font-weight:bold;padding-bottom:0.4rem">会员权益</div>
      <div  style="padding-bottom:1rem">1、每日到店可免费领取一杯豆浆；</br>
        2、单次续费有效期30天；</br>
        3、每日可凭会员9折购买其它早餐。
      </div>
      <div style="font-weight: bold;margin-top:1rem；padding: 0.4rem 0;color: #fb4949">
        温馨提示：支付时请备注手机号
      </div>
      <div style="padding-top: 0.4rem">付款后2小时内生效，如超时未生效，请添加微信告知，我们在收到信息后及时处理。</div>
      <div class="payImg">
        <img class="img" src="../assets/img/wxpay2.png" alt="" />
      </div>
      <div class="wxhBox">
        <div class="wxImg">
          <img class="img" src="../assets/img/wxh.svg" alt="" />
        </div>
        <div>
          <div>微信号</div>
          <div>13718345029</div>
        </div>
      </div>
    </div>
    <div class="saveBox" v-if="saveShow == true">
      <div class="sb_content">
        <div class="sbc_tit">提示</div>
        <div class="sbc_dec">您是否已经完成付款？</div>
        <div class="sbc_btn">
          <div class="sbct_item" @click="colse()">暂时不用</div>
          <div class="sbct_item btn" @click="openSave()">完成付款</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postRechargeInfo } from '@/api/member.js'
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  data() {
    return {
      saveShow: false,
      fromData: {
        userId: null,
        cardType: 1,
        money: 9.9,
        status: false,
        remarks: '如会员信息没有即时更新，请在首页右上角点击刷新一下'
      }
    }
  },
  mounted() {
    this.fromData.userId = session.getUserInfo().id
  },
  methods: {
    goback() {
      this.saveShow = true
    },
    colse() {
      this.saveShow = false
      this.$router.push({ path: '/home' })
    },
    async openSave() {
      const res = await postRechargeInfo(this.fromData)
      if (res.success) {
        this.saveShow = false
        this.$router.push({ path: '/home' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.about {
  font-size: 1rem;
}
.navBar {
  padding: 1rem;
  margin-top: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  font-weight: bold;
  .nb_img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
  }
}
.content {
  text-align: left;
  padding: 1rem;
  .payImg {
    margin-top: 1rem;
  }
  .wxhBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .wxImg {
      width: 8rem;
      height: 8rem;
      margin-right: 1rem;
    }
  }
}
.saveBox {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .sb_content {
    width: 20rem;
    background: #fff;
    border-radius: 16px;
    padding-top: 1rem;
    font-size: 1.2rem;
    .sbc_tit {
      font-weight: bold;
    }
    .sbc_dec {
      padding: 2rem 0;
    }
    .sbc_btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #f2f3f4;
      .sbct_item {
        width: 450px / 2;
        text-align: center;
        padding: 24px 0;
      }
      .btn {
        color: #fb4949;
        font-weight: bold;
      }
    }
  }
}
</style>