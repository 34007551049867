<template>
  <div class="logon">
    <div class="title">欢迎登录！</div>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
        <div class="codeDec" v-if="mobileRes == false">请输入正确手机号!</div>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="ruleForm.code" placeholder="验证码">
          <template slot="append">
            <div @click="openCode" v-if="sysCode == ''">发送验证码</div>
            <div @click="openCode" v-if="sysCode != ''">已发送</div>
          </template>
        </el-input>
        <div class="codeDec" v-if="codeRes == false">验证码输入有误！</div>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="submitForm()">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getSignValidateCode, getLogin, getProfile } from '@/api/member.js'
import session from '@/utils/session'
export default {
  data() {
    return {
      ruleForm: {
        mobile: '',
        code: ''
      },
      sysCode: '',
      codeRes: true,
      mobileRes: true
    }
  },
  methods: {
    async openCode() {
      const res = await getSignValidateCode(this.ruleForm.mobile)
      if (res.success) {
        this.ruleForm.code = res.data
        this.sysCode = res.data
        this.codeRes = true
        this.mobileRes = true
      } else {
        this.mobileRes = false
      }
    },
    // 登录
    async submitForm() {
      if (this.ruleForm.code != '' && this.ruleForm.mobile != '') {
        if (this.sysCode == this.ruleForm.code) {
          const res = await getLogin(this.ruleForm)
          session.setSession(res.data)
          this.parseToken()
        } else {
          this.codeRes = false
        }
      } else {
        this.codeRes = false
        this.mobileRes = false
      }
    },
    //解析token
    async parseToken() {
      let result = await getProfile()
      session.setData('userInfo', result.data)
      this.$router.push({ path: '/sysHomeView' })
    }
  }
}
</script>
<style lang="less" scoped>
.logon {
  margin: 0 1rem;
  margin-top: 6rem;
}
.title {
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 1rem;
}
.btn {
  margin-top: 4rem;
}
.codeDec {
  color: #fb4949;
  text-align: left;
  font-size: 0.8rem;
}
</style>
