<template>
  <div class="home">
    <div class="card">
      <div class="card_inner">
        <div class="ci_topBox">
          <div class="cit_img">
            <img class="img" src="../assets/img/logo.png" alt="" />
          </div>
          <div class="cit_txt">
            <div class="citt_tit">早餐名称</div>
            <div class="citt_dec">这是一段描述</div>
          </div>
        </div>
        <div class="ci_bottom">ID:{{ userInfo.id }}</div>
      </div>
    </div>
    <div style="font-size: 0.8rem; color: #fb4949">基础会员升级尊享会员，可添加客服微信返现金</div>
    <div class="card_info">
      <el-row>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <div class="ci_tit">会员类型</div>
            <div class="ci_dec">
              {{ this.userInfo.cardType == 1 ? '基础' : this.userInfo.cardType == 2 ? '尊享' : '无' }}
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <div class="ci_tit">截止日期</div>
            <div class="ci_dec">
              {{ !this.userInfo.memberEndTime ? '无' : this.date(this.userInfo.memberEndTime, 'YYYY-MM-DD') }}
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <div class="ci_tit">优惠</div>
            <div class="ci_dec">
              {{ !this.userInfo.preferential ? '无' : this.userInfo.preferential }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="card_btn">
      <el-row :gutter="20">
        <el-col :span="24" v-if="userInfo.cardType == 0 || userInfo.cardType == null">
          <div class="grid-content bg-purple" @click="openPay()">
            <div class="cb_left">开通</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="userInfo.cardType != 0 && userInfo.cardType != null">
        <el-col :span="12">
          <div class="grid-content bg-purple" @click="openPay()">
            <div class="cb_left">续费</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple cb_rightBox" @click="openVote()">
            <div class="cb_right">菜品投票</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="card_list">
      <div class="cl_items" v-for="(v, i) in listData" :key="i" @click="openList(v, i)">
        <div class="cli_tit">{{ v.value }}</div>
        <div class="">
          <div>{{ v.time }}</div>
        </div>
        <div class="right_img" v-if="i != 1">
          <img class="img" src="../assets/img/right_arrow.svg" alt="" />
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <div class="openPayBox" v-if="openPayBox == true">
      <div class="op_content">
        <div class="opc_titBox">
          <div class="opct_tit">会员续费</div>
          <div class="opct_img" @click="clolse()">
            <img class="img" src="../assets/img/close.svg" alt="" />
          </div>
        </div>
        <div class="opc_content">
          <div class="opcc_inner" @click="openPay1()">
            <div>
              <div>尊享会员(30天) <span style="color: #fb4949">推荐</span></div>
              <div style="font-weight: 400; opacity: 0.7; font-size: 0.8rem">每日免费领取一份早餐</div>
            </div>
            <div style="color: #fb4949">¥99</div>
          </div>
          <div class="opcc_inner" @click="openPay2()">
            <div>
              <div>基础会员(30天)</div>
              <div style="font-weight: 400; opacity: 0.7; font-size: 0.8rem">每日免费领取一杯豆浆</div>
            </div>
            <div style="color: #fb4949">¥19.9</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import moment from 'moment'
export default {
  data() {
    return {
      userInfo: null,
      openPayBox: false,
      listData: [
        {
          value: '我的信息'
        },
        {
          value: '加入时间',
          time: ''
        },
        {
          value: '充值记录'
        },
        {
          value: '会员详情'
        },
        {
          value: '本周会员菜品'
        }
      ]
    }
  },
  mounted() {
    this.userInfo = session.getUserInfo()
    this.listData[1].time = this.date(session.getUserInfo().createTime, 'YYYY-MM-DD')
  },
  methods: {
    // 将时间戳转化为年月日
    date(date, fomatter) {
      if (!date) {
        return ''
      }
      var fmt = fomatter || 'YYYY-MM-DD HH:mm:ss'
      return moment(date).format(fmt)
    },
    openVote() {
      this.$router.push({ path: '/memberVote' })
    },
    openPay() {
      this.openPayBox = true
    },
    openList(v, i) {
      if (i == 0) {
        this.$router.push({ path: '/my' })
      } else if (i == 2) {
        this.$router.push({ path: '/recharge' })
      } else if (i == 3) {
        this.$router.push({ path: '/memberDetails' })
      } else if (i == 4) {
        this.$router.push({ path: '/memberDishes' })
      }
    },
    clolse() {
      this.openPayBox = false
    },
    openPay1() {
      this.$router.push({ path: '/pay' })
    },
    openPay2() {
      this.$router.push({ path: '/pay2' })
    }
  }
}
</script>
<style lang="less" scoped>
.img {
  width: 100%;
  height: 100%;
}
.card {
  margin: 0.6rem;
  .card_inner {
    background: #fb4949;
    border-radius: 16px;
    height: 10rem;
    padding: 1.2rem;
    .ci_topBox {
      display: flex;
      align-items: center;
      justify-content: start;
      .cit_img {
        width: 3rem;
        height: 3rem;
        background: #fff;
        border-radius: 5rem;
        margin-right: 1rem;
        overflow: hidden;
        border: 4px solid #fff;
      }
      .cit_txt {
        text-align: left;
        .citt_tit {
          font-size: 1.2rem;
          font-weight: bold;
          color: #fff;
        }
        .citt_dec {
          font-size: 0.8rem;
          color: #fff;
        }
      }
    }
    .ci_bottom {
      font-size: 1rem;
      color: #fff;
      text-align: left;
      margin-top: 5rem;
    }
  }
}
.card_info {
  margin: 1.4rem 0;
  .ci_tit {
    font-size: 1rem;
    margin-bottom: 0.6rem;
  }
  .ci_dec {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
.card_btn {
  padding: 0 1rem;
  .grid-content {
    background: #fb4949;
    border-radius: 16px;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
  }
  .grid-content:active {
    opacity: 0.7;
  }
  .cb_rightBox {
    background: rgba(251, 73, 73, 0.1);
    color: #fb4949;
  }
}
.card_list {
  margin-top: 1.2rem;
  padding: 0 1rem;
  .cl_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    border-bottom: 1px solid #f2f3f4;
    padding: 1rem 0;
  }
  .cl_items:active {
    opacity: 0.7;
  }
  .right_img {
    width: 1rem;
    height: 1rem;
  }
}
// 弹框
.openPayBox {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  .op_content {
    position: absolute;
    bottom: 0;
    width: 100vw;
    padding-bottom: 1rem;
    background: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    .opc_titBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      .opct_tit {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .opct_img {
        width: 1rem;
        height: 1rem;
      }
    }
    .opc_content {
      padding: 0 1rem;
      font-size: 1rem;
      .opcc_inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #f2f3f4;
        border-radius: 16px;
        padding: 1rem;
        margin-bottom: 1rem;
        div {
          font-weight: bold;
        }
      }
      .opcc_inner:active {
        border: rgba(251, 73, 73, 0.1) 2px solid;
        background: rgba(251, 73, 73, 0.1);
      }
      .op_btn {
        margin-top: 1rem;
        background: #fb4949;
        border-radius: 16px;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
</style>
